import navigation from "@constants/navigation";
import { Link } from "react-router-dom";

const Footer = () => {
	return (
		<footer className="bg-gray-100 print:hidden mt-4">
			<div className="max-w-7xl mx-auto py-6 px-4 overflow-hidden sm:px-6 lg:px-8">
				<nav
					className="-mx-5 -my-2 flex flex-wrap justify-center"
					aria-label="Footer"
				>
					{navigation.main.map((item) => {
						return (
							<div key={item.name} className="px-5 py-2">
								<Link
									to={item.path}
									className="text-base text-gray-500 hover:text-gray-900"
								>
									{item.name}
								</Link>
							</div>
						);
					})}
				</nav>
				<div className="mt-8 flex justify-center space-x-6">
					{navigation.social.map((item) => {
						return (
							<a
								key={item.name}
								href={item.href}
								className="text-gray-400 hover:text-gray-500"
							>
								<span className="sr-only">{item.name}</span>
								<item.icon
									className="h-6 w-6"
									aria-hidden="true"
								/>
							</a>
						);
					})}
				</div>
				<p className="mt-8 text-center text-base text-gray-400">
					&copy; 2022 Emma Pinto. Derechos reservados.
				</p>
			</div>
		</footer>
	);
};

export default Footer;
