import Template from "@templates/Main";

const withTemplate = (Component) => {
	const WrappedComponent = (props) => {
		return (
			<Template>
				<Component {...props} />
			</Template>
		);
	};
	return WrappedComponent;
};

export default withTemplate;
