import { Fragment, useState } from "react";
import { Disclosure } from "@headlessui/react";
import { NewspaperIcon, MenuIcon, XIcon } from "@heroicons/react/outline";
// import Searchbar from "@atoms/Searchbar";
import { Link, useLocation } from "react-router-dom";
import logo from "@assets/Dark.png";
import NewsletterModal from "@organisms/NewsletterModal";
import ReactTooltip from "react-tooltip";
import ComboBox from "@molecules/ComboBox";

const navElements = [
	{ title: "Videos", route: "/videos", base: false },
	{ title: "Blogs", route: "/blogs", base: false },
	{ title: "Recetas", route: "/", base: true },
	{ title: "Acerca de", route: "/about", base: false },
	{ title: "Contacto", route: "/contact", base: false },
];

const Navbar = () => {
	const location = useLocation();

	const [modal, toggleModal] = useState(false);
	const [tooltip, showTooltip] = useState(true);

	return (
		<>
			<NewsletterModal showModal={modal} toggleModal={toggleModal} />

			<Disclosure
				as="nav"
				className="sticky top-0 z-50 mb-4 bg-white shadow print:hidden"
			>
				{({ open }) => {
					return (
						<>
							<div className="px-2 mx-auto max-w-7xl sm:px-4 lg:px-8">
								<div className="flex items-center justify-between h-16">
									<div className="flex flex-1 px-2 lg:px-0">
										<div className="flex items-center flex-shrink-0">
											<Link to="/">
												<img
													className="block w-auto h-8 lg:hidden"
													src={logo}
													alt="Workflow"
												/>
											</Link>
											<Link to="/">
												<img
													className="hidden w-auto h-8 lg:block"
													src={logo}
													alt="Home"
												/>
											</Link>
										</div>
										<div className="hidden lg:ml-6 lg:flex lg:space-x-8">
											{navElements.map((element, i) => {
												return (
													<Link
														key={i}
														to={element.route}
														className={`${
															(element.base &&
																location.pathname.includes(
																	"recipes",
																)) ||
															location.pathname ===
																element.route ||
															(location.pathname.includes(
																element.route,
															) &&
																element.route !==
																	"/")
																? "border-indigo-500 text-gray-900"
																: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
														}  inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium`}
													>
														{element.title}
													</Link>
												);
											})}
										</div>
									</div>

									{/* <Searchbar
										state={search}
										setState={setSearch}
									/> */}
									<ComboBox />

									<div className="flex items-center lg:hidden">
										{/* Mobile menu button */}
										<Disclosure.Button className="inline-flex items-center justify-center p-2 text-gray-400 rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
											<span className="sr-only">
												Open main menu
											</span>
											{open ? (
												<XIcon
													className="block w-6 h-6"
													aria-hidden="true"
												/>
											) : (
												<MenuIcon
													className="block w-6 h-6"
													aria-hidden="true"
												/>
											)}
										</Disclosure.Button>
									</div>
									<div className="hidden lg:ml-4 lg:flex lg:items-center">
										<button
											type="button"
											data-for="newsletter-modal"
											data-tip
											onMouseEnter={() => {
												showTooltip(true);
											}}
											onMouseLeave={() => {
												showTooltip(false);
												setTimeout(() => {
													return showTooltip(true);
												}, 50);
											}}
											onFocus={() => {
												return showTooltip(true);
											}}
											onBlur={() => {
												showTooltip(false);
												setTimeout(() => {
													return showTooltip(true);
												}, 50);
											}}
											onClick={() => {
												return toggleModal(true);
											}}
											className="flex-shrink-0 p-1 text-gray-400 bg-white rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
										>
											<span className="sr-only">
												View notifications
											</span>
											<NewspaperIcon
												className="w-6 h-6"
												aria-hidden="true"
												tabIndex={-1}
											/>
											{tooltip && (
												<ReactTooltip
													id="newsletter-modal"
													effect="solid"
												>
													Recibir nuevas recetas
												</ReactTooltip>
											)}
										</button>
									</div>
								</div>
							</div>

							<Disclosure.Panel className="lg:hidden">
								<div className="pt-2 pb-3 space-y-1">
									{navElements.map((element, i) => {
										return (
											<Disclosure.Button
												key={i}
												as={Fragment}
											>
												<Link
													to={element.route}
													className={`${
														(element.base &&
															location.pathname.includes(
																"recipes",
															)) ||
														location.pathname ===
															element.route ||
														(location.pathname.includes(
															element.route,
														) &&
															element.route !==
																"/")
															? "bg-indigo-50 border-indigo-500 text-indigo-700"
															: "border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800"
													}  block pl-3 pr-4 py-2 border-l-4 text-base font-medium`}
												>
													{element.title}
												</Link>
											</Disclosure.Button>
										);
									})}
								</div>
							</Disclosure.Panel>
						</>
					);
				}}
			</Disclosure>
		</>
	);
};

export default Navbar;
