import Navbar from "@organisms/Navbar";
import Footer from "@organisms/Footer";
const Main = ({ children }) => {
	return (
		<div className="flex flex-col min-h-screen">
			<Navbar />
			<div className="container flex-1 px-4 mx-auto sm:px-6 lg:px-8">
				{children}
			</div>
			<Footer />
		</div>
	);
};

export default Main;
