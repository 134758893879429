const About = () => {
	return (
		<div className="relative py-16 overflow-hidden bg-white">
			<div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
				<div
					className="relative h-full mx-auto text-lg max-w-prose"
					aria-hidden="true"
				>
					<svg
						className="absolute transform translate-x-32 top-12 left-full"
						width={404}
						height={384}
						fill="none"
						viewBox="0 0 404 384"
					>
						<defs>
							<pattern
								id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
								x={0}
								y={0}
								width={20}
								height={20}
								patternUnits="userSpaceOnUse"
							>
								<rect
									x={0}
									y={0}
									width={4}
									height={4}
									className="text-gray-200"
									fill="currentColor"
								/>
							</pattern>
						</defs>
						<rect
							width={404}
							height={384}
							fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
						/>
					</svg>
					<svg
						className="absolute transform -translate-x-32 -translate-y-1/2 top-1/2 right-full"
						width={404}
						height={384}
						fill="none"
						viewBox="0 0 404 384"
					>
						<defs>
							<pattern
								id="f210dbf6-a58d-4871-961e-36d5016a0f49"
								x={0}
								y={0}
								width={20}
								height={20}
								patternUnits="userSpaceOnUse"
							>
								<rect
									x={0}
									y={0}
									width={4}
									height={4}
									className="text-gray-200"
									fill="currentColor"
								/>
							</pattern>
						</defs>
						<rect
							width={404}
							height={384}
							fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
						/>
					</svg>
					<svg
						className="absolute transform translate-x-32 bottom-12 left-full"
						width={404}
						height={384}
						fill="none"
						viewBox="0 0 404 384"
					>
						<defs>
							<pattern
								id="d3eb07ae-5182-43e6-857d-35c643af9034"
								x={0}
								y={0}
								width={20}
								height={20}
								patternUnits="userSpaceOnUse"
							>
								<rect
									x={0}
									y={0}
									width={4}
									height={4}
									className="text-gray-200"
									fill="currentColor"
								/>
							</pattern>
						</defs>
						<rect
							width={404}
							height={384}
							fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
						/>
					</svg>
				</div>
			</div>
			<div className="relative px-4 sm:px-6 lg:px-8">
				<div className="mx-auto text-lg max-w-prose">
					<h1>
						<span className="block mt-2 text-3xl font-extrabold leading-8 tracking-tight text-center text-gray-900 sm:text-4xl">
							Chef Emma Pinto
						</span>
					</h1>
					<p className="mx-auto mt-6 prose prose-lg text-gray-500 prose-indigo">
						¡Hola y bienvenidos! Soy Emma Pinto, y si hay algo que
						me define, es mi amor por la cocina. Me licencié en
						Administración Turística por la Universidad Anáhuac y
						continué mis estudios en instituciones de prestigio como
						Cornell University y el Culinary Institute of America.
					</p>
				</div>
				<div className="mx-auto mt-6 prose prose-lg text-gray-500 prose-indigo">
					<p>
						Desde temprana edad, me he sentido como en casa en la
						cocina. Monté mi propia empresa de repostería a los 18
						años y hoy me desempeño como directora culinaria en
						Grupo Cassatt, supervisando una amplia gama de centros
						de banquetes y restaurantes de especialidades.
					</p>
					<figure>
						<img
							className="w-full rounded-lg"
							src="https://firebasestorage.googleapis.com/v0/b/cooking-35f0a.appspot.com/o/2023-10-21%2010.22.41.jpg?alt=media&token=cb0eebe6-07a3-4139-bb93-026b41f6d4f2&_gl=1*1oi7rwm*_ga*MTQ4NTY5MzUwMi4xNjY0NDEwNzgz*_ga_CW55HF8NVT*MTY5NzkwNTMwOC4zMDMuMS4xNjk3OTA1MzcyLjYwLjAuMA.."
							alt=""
							width={1310}
							height={873}
						/>
						{/* <figcaption>Caption cerda</figcaption> */}
					</figure>

					<p className="mx-auto mt-6 prose prose-lg text-gray-500 prose-indigo">
						Pero mi vida no ha girado solo en torno a los fogones. A
						los 50 años, descubrí otra pasión: la fotografía. Y
						aunque una pandemia me llevó de nuevo a mis raíces
						culinarias, el viaje fue increíblemente enriquecedor.
					</p>

					<p className="mx-auto mt-6 prose prose-lg text-gray-500 prose-indigo">
						Durante mi trayectoria, he tenido la fortuna de
						compartir mi amor por la gastronomía mexicana a través
						de diversas plataformas: desde ser Chef Ejecutiva en la
						Universidad Anáhuac del Sur y aparecer en programas de
						televisión hasta escribir para revistas gastronómicas.
						También he sido parte de ‘Sabores de Familia’, un
						programa que se transmitió en toda América Latina.
					</p>
					<p className="mx-auto mt-6 prose prose-lg text-gray-500 prose-indigo">
						La enseñanza es otra de mis grandes pasiones. Durante 15
						años, he dado clases a todo tipo de alumnos, desde
						estudiantes universitarios hasta amas de casa y niños.
						Esta pasión por compartir llevó a la creación de mi
						canal de YouTube en abril de 2020, especialmente para
						ayudar a quienes estaban confinados por la Covid-19.
						Hoy, sigo compartiendo recetas y consejos en mis redes
						sociales, que incluyen Instagram, Facebook y TikTok.
					</p>
					<p className="mx-auto mt-6 prose prose-lg text-gray-500 prose-indigo">
						Como siempre digo, ‘Cuando tu pasión se convierte en tu
						trabajo; deja de ser un trabajo’. Y cada día, vivo esa
						pasión, aprendiendo y creciendo para llevar lo mejor de
						la cocina a tu mesa.
					</p>
					<p className="mx-auto mt-6 prose prose-lg text-gray-500 prose-indigo">
						¡Espero que disfruten de este viaje culinario tanto como
						yo!”
					</p>

					{/* <h2>Cosas del futuro</h2>
					<p>
						Purus morbi dignissim senectus mattis{" "}
						<Link to="/contact">contacto</Link>. Amet, massa quam
						varius orci dapibus volutpat cras. In amet eu ridiculus
						leo sodales cursus tristique. Tincidunt sed tempus ut
						viverra ridiculus non molestie. Gravida quis fringilla
						amet eget dui tempor dignissim. Facilisis auctor
						venenatis varius nunc, congue erat ac. Cras fermentum
						convallis quam.
					</p>
					<p>
						Faucibus commodo massa rhoncus, volutpat. Dignissim sed
						eget risus enim. Mattis mauris semper sed amet vitae sed
						turpis id. Id dolor praesent donec est. Odio penatibus
						risus viverra tellus varius sit neque erat velit.
					</p> */}
				</div>
			</div>
		</div>
	);
};

export default About;
