import { useParams } from "react-router-dom";
import Loader from "@atoms/Loader";
import parseDate from "@utils/parseDate";
import useData from "@hooks/useData";

const Blog = () => {
	const { id } = useParams();
	const { blogs } = useData();
	const blog = blogs.find((item) => {
		return item.id === id;
	});

	const BlogElement = ({ blog }) => {
		const date = new Date(blog.date.seconds * 1000);

		return (
			<div className="relative py-4 overflow-hidden bg-white">
				<div className="relative px-4 sm:px-6 lg:px-8">
					<div className="mx-auto text-lg max-w-prose">
						<div className="flex justify-between pb-12 mx-auto text-base text-gray-700 print:hidden">
							<div className=" first-letter:capitalize">
								{parseDate(date)}
							</div>
						</div>
						<h1>
							{/* <span className="block text-base font-semibold tracking-wide text-center text-indigo-600 uppercase">
                  Introducing
                </span> */}
							<span className="block mt-2 text-3xl font-extrabold leading-8 tracking-tight text-center text-gray-900 sm:text-4xl">
								{blog.title}
							</span>
						</h1>
					</div>
					<div className="mx-auto mt-6 prose prose-lg text-gray-500 prose-indigo">
						{blog.content.map((element, i) => {
							return element.substring(0, 4) !== "http" ? (
								<p key={i}>{element}</p>
							) : (
								<figure key={i}>
									<img
										className="w-full rounded-lg"
										src={element}
										alt=""
										width={1310}
										height={873}
									/>
								</figure>
							);
						})}
					</div>
				</div>
			</div>
		);
	};

	return blog ? <BlogElement blog={blog} /> : <Loader />;
};

export default Blog;
