const dateOptions = {
	weekday: "long",
	year: "numeric",
	month: "long",
	day: "numeric",
};

const parseDate = (date) => {
	return date.toLocaleDateString("es-MX", dateOptions);
};

export default parseDate;
