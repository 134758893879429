import { useParams } from "react-router-dom";
import useData from "@hooks/useData";
import Loader from "@atoms/Loader";

const Video = () => {
	const { id } = useParams();

	const { videos } = useData();
	const video = videos.find((vid) => {
		return vid.id === id;
	});

	return video ? (
		<div className="max-w-6xl mx-auto">
			<div className="block py-4 text-2xl prose text-center text-gray-800 sm:text-4xl">
				{video.title}
			</div>
			<div className="flex items-center justify-center overflow-hidden rounded-xl bg-gray-900/50">
				<div className="relative flex-1 p-4 overflow-auto rounded-xl sm:p-8">
					<div className="aspect-w-16 aspect-h-9">
						<iframe
							className="rounded-lg shadow-lg"
							src={video.URL}
							title={video.title}
							allowFullScreen
						/>
					</div>
				</div>
			</div>
		</div>
	) : (
		<Loader />
	);
};

export default Video;
