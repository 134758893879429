import { BrowserRouter, Routes, Route } from "react-router-dom";
import ScrollToTop from "@routes/ScrollToTop";

import AboutContent from "@pages/About";
import BlogContent from "@pages/Blog";
import BlogsContent from "@pages/Blogs";
import ContactContent from "@pages/Contact";
import RecipesContent from "@pages/Recipes";
import RecipeContent from "@organisms/Recipe";
import VideosContent from "@pages/Videos";
import VideoContent from "@pages/Video";
import CollaborationsContent from "@pages/Collaborations";
import LoginContent from "@pages/Login";
import AdminContent from "@pages/Admin";
import NotFoundContent from "@organisms/NotFound";

import { useAuth } from "@hooks/useAuth";

import PrivateRoute from "@routes/PrivateRoute";
import PublicOnlyRoute from "@routes/PublicOnlyRoute";

import withTemplate from "../HOC/withTemplate";

const Router = () => {
	const auth = useAuth();

	const About = withTemplate(AboutContent);
	const Blog = withTemplate(BlogContent);
	const Blogs = withTemplate(BlogsContent);
	const Contact = withTemplate(ContactContent);
	const Recipes = withTemplate(RecipesContent);
	const Recipe = withTemplate(RecipeContent);
	const Videos = withTemplate(VideosContent);
	const Video = withTemplate(VideoContent);
	const Collaborations = withTemplate(CollaborationsContent);
	const Login = withTemplate(LoginContent);
	const Admin = withTemplate(AdminContent);
	const NotFound = withTemplate(NotFoundContent);

	return (
		<BrowserRouter>
			<ScrollToTop />
			<Routes>
				<Route path="/" element={<Recipes admin={!!auth.user} />} />
				<Route
					path="/recipes/:id"
					element={<Recipe admin={!!auth.user} />}
				/>
				<Route path="/videos" element={<Videos />} />
				<Route path="/videos/:id" element={<Video />} />
				<Route path="/blogs" element={<Blogs admin={false} />} />
				<Route path="/blogs/:id" element={<Blog />} />
				<Route path="/contact" element={<Contact />} />
				<Route path="/about" element={<About />} />
				<Route path="/collaborations" element={<Collaborations />} />
				<Route
					path="/admin"
					element={
						<PrivateRoute>
							<Admin />
						</PrivateRoute>
					}
				/>

				<Route
					path="/login"
					element={
						<PublicOnlyRoute>
							<Login />
						</PublicOnlyRoute>
					}
				/>
				<Route path="*" element={<NotFound />} />
			</Routes>
		</BrowserRouter>
	);
};

export default Router;
