import { useState } from "react";
import { useAuth } from "@hooks/useAuth";
import logo from "@assets/Dark.png";
import { firebaseErrorCodes } from "@constants/index";

const SignIn = () => {
	const auth = useAuth();
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");

	return (
		<>
			<div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
				<div className="max-w-md w-full space-y-8">
					<div>
						<img
							className="mx-auto h-12 w-auto"
							src={logo}
							alt="Workflow"
						/>
						<h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
							Ingresar al panel administrativo
						</h2>
					</div>
					<form
						className={`${auth.error ? "" : "space-y-6"} mt-8`}
						action="#"
						method="POST"
					>
						<input
							type="hidden"
							name="remember"
							defaultValue="true"
						/>
						<div className="rounded-md shadow-sm -space-y-px">
							<div>
								<label
									htmlFor="email-address"
									className="sr-only"
								>
									Correo Electrónico
								</label>
								<input
									id="email-address"
									name="email"
									type="email"
									autoComplete="email"
									required
									className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
									placeholder="Email address"
									value={email}
									onChange={(e) => {
										setEmail(e.target.value);
									}}
								/>
							</div>
							<div>
								<label htmlFor="password" className="sr-only">
									Contraseña
								</label>
								<input
									id="password"
									name="password"
									type="password"
									autoComplete="current-password"
									required
									className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
									placeholder="Password"
									value={password}
									onChange={(e) => {
										setPassword(e.target.value);
									}}
								/>
							</div>
						</div>
						{auth.error && (
							<div className="text-sm pl-2 pt-2 text-red-500 mb-6">
								{firebaseErrorCodes(auth.error)}
							</div>
						)}

						<div>
							<button
								disabled={!(email && password)}
								type="button"
								onClick={() => {
									return auth.signIn(email, password);
								}}
								className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 disabled:bg-slate-500 disabled:cursor-not-allowed focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
							>
								Ingresar
							</button>
						</div>
					</form>
				</div>
			</div>
		</>
	);
};

export default SignIn;
