import Grid from "@organisms/Grid";
import useData from "@hooks/useData";

const Recipes = ({ admin }) => {
	const { recipes, recipeStatus: status } = useData();

	return <Grid data={recipes} status={status} type="recipes" admin={admin} />;
};

export default Recipes;
