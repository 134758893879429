import createDataContext from "@context/createDataContext";
import { database, collection, getDocs } from "@utils/firebase";
import useFilter from "@hooks/useFilter";

const reducer = (state, action) => {
	switch (action.type) {
		case "get_recipes":
			return action.payload;
		case "filter_recipes":
			return { ...state, filteredRecipes: action.payload };
		default:
			return state;
	}
};

const getRecipes = (dispatch) => {
	return async () => {
		const recipes = [];
		try {
			const querySnapshot = await getDocs(
				collection(database, "recipes"),
			);
			querySnapshot.forEach((doc) => {
				const parsedDoc = { ...doc.data(), id: doc.id, type: 0 };
				recipes.push(parsedDoc);
			});
			const status = "success";
			const filteredRecipes = [];
			dispatch({
				type: "get_recipes",
				payload: { data: recipes, status, filteredRecipes },
			});
		} catch (error) {
			console.log(error);
		}
	};
};

const filterRecipes = (dispatch) => {
	return async (collection, search) => {
		const filteredSearch = useFilter(collection, search);
		dispatch({
			type: "filter_recipes",
			payload: { filteredSearch },
		});
	};
};

export const { Context, Provider } = createDataContext(
	reducer,
	{ getRecipes, filterRecipes },
	{ data: [], status: "idle", filteredRecipes: [] },
);
