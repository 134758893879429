import { Navigate, useLocation } from "react-router-dom";

import { useAuth } from "@hooks/useAuth";

const PublicOnlyRoute = ({ children }) => {
	const auth = useAuth();
	const location = useLocation();

	return auth.user ? (
		<Navigate to={"/admin"} state={{ from: location }} replace />
	) : (
		children
	);
};
export default PublicOnlyRoute;
