import { Provider as RecipeProvider } from "@context/RecipeContext";
import { Provider as BlogProvider } from "@context/BlogContext";
import { Provider as VideoProvider } from "@context/VideoContext";
import { AuthProvider } from "@hooks/useAuth";

import Router from "@router";
const App = () => {
	return (
		<AuthProvider>
			<VideoProvider>
				<RecipeProvider>
					<BlogProvider>
						<Router />
					</BlogProvider>
				</RecipeProvider>
			</VideoProvider>
		</AuthProvider>
	);
};

export default App;
