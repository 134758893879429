import Grid from "@organisms/Grid";
import useData from "@hooks/useData";

const Blogs = ({ admin }) => {
	const { blogs, blogStatus: status } = useData();

	return (
		<>
			<Grid data={blogs} status={status} admin={admin} />
		</>
	);
};

export default Blogs;
