import { useState, useEffect } from "react";

const useFilter = (collection, search) => {
	const [filteredSearch, setFilteredSearch] = useState([]);

	useEffect(() => {
		const filterArray = [];
		if (search !== "") {
			collection.forEach((document) => {
				if (document.title.toLowerCase().includes(search)) {
					filterArray.push(document);
				}
			});
			setFilteredSearch(filterArray);
		} else setFilteredSearch(collection);
	}, [collection, search]);

	return [filteredSearch];
};

export default useFilter;
