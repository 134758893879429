import Loader from "@atoms/Loader";
import { useNavigate } from "react-router-dom";
import useData from "@hooks/useData";

const Videos = () => {
	const navigate = useNavigate();
	const { videos, videoStatus: status } = useData();

	if (status === "idle") {
		return <Loader />;
	}
	return (
		<ul className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
			{videos.map((video, i) => {
				return (
					<li key={i} className="relative">
						<div className="block w-full overflow-hidden bg-gray-100 rounded-lg group aspect-w-10 aspect-h-7 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500">
							<img
								src={video.thumbnail}
								alt=""
								className="object-cover pointer-events-none group-hover:opacity-75"
							/>
							<button
								onClick={() => {
									return navigate(`${video.id}`);
								}}
								type="button"
								className="absolute inset-0 focus:outline-none"
							>
								<span className="sr-only">
									View details for {video.title}
								</span>
							</button>
						</div>
						<p className="block mt-2 text-sm font-medium text-center text-gray-900 lowercase pointer-events-none first-letter:uppercase">
							{video.title}
						</p>
						{/* <p className="block text-sm font-medium text-gray-500 pointer-events-none">
{recipe.size}
</p> */}
					</li>
				);
			})}
		</ul>
	);
};

export default Videos;
