import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { NewspaperIcon } from "@heroicons/react/outline";
import Checkbox from "@molecules/Checkbox";
import Input from "@atoms/Input";
import subscribe from "@utils/subscribe";

const NewsletterModal = ({ showModal, toggleModal }) => {
	const cancelButtonRef = useRef(null);

	const [recipe, setRecipe] = useState(false);
	const [blog, setBlog] = useState(false);
	const [email, setEmail] = useState("");
	const [success, setSuccess] = useState(false);

	return (
		<Transition.Root show={showModal} as={Fragment}>
			<Dialog
				as="div"
				className="relative z-50"
				initialFocus={cancelButtonRef}
				onClose={toggleModal}
			>
				<Transition.Child
					as={Fragment}
					enter="ease-out duration-300"
					enterFrom="opacity-0"
					enterTo="opacity-100"
					leave="ease-in duration-200"
					leaveFrom="opacity-100"
					leaveTo="opacity-0"
				>
					<div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
				</Transition.Child>

				<div className="fixed inset-0 z-10 overflow-y-auto">
					<div className="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							enterTo="opacity-100 translate-y-0 sm:scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 translate-y-0 sm:scale-100"
							leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
						>
							<Dialog.Panel className="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
								<div>
									<div className="flex items-center justify-center w-12 h-12 mx-auto bg-green-100 rounded-full">
										<NewspaperIcon
											className="w-6 h-6 text-green-600"
											aria-hidden="true"
										/>
									</div>
									<div className="mt-3 text-center sm:mt-5">
										<Dialog.Title
											as="h3"
											className="text-lg font-medium leading-6 text-gray-900"
										>
											Recibe nuevas recetas y blogs
										</Dialog.Title>
										{success ? (
											"Muchas gracias por el registro! Recibirás nueva información en cuanto este disponible."
										) : (
											<>
												<div className="text-left">
													<Checkbox
														blog={blog}
														setBlog={setBlog}
														recipe={recipe}
														setRecipe={setRecipe}
													/>
												</div>
												<div className="mt-4 text-left">
													<Input
														email={email}
														setEmail={setEmail}
													/>
												</div>
											</>
										)}
									</div>
								</div>
								<div
									className={`mt-5 sm:mt-6 ${
										success
											? ""
											: "sm:grid sm:grid-cols-2 sm:grid-flow-row-dense"
									} sm:gap-3  `}
								>
									{success ? (
										<button
											type="button"
											className="inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
											onClick={() => {
												return toggleModal(false);
											}}
										>
											Continuar
										</button>
									) : (
										<>
											<button
												type="button"
												className="inline-flex justify-center w-full px-4 py-2 text-base font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
												onClick={() => {
													return subscribe(
														email,
														recipe,
														blog,
														setSuccess(true),
													);
												}}
											>
												Registro
											</button>
											<button
												type="button"
												className="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
												onClick={() => {
													return toggleModal(false);
												}}
												ref={cancelButtonRef}
											>
												Cancelar
											</button>
										</>
									)}
								</div>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	);
};

export default NewsletterModal;
