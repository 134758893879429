import { useState } from "react";
import { useParams } from "react-router-dom";
import useData from "@hooks/useData";
import Loader from "@atoms/Loader";
import { PrinterIcon, UploadIcon } from "@heroicons/react/outline";
import ReactTooltip from "react-tooltip";
import parseDate from "@utils/parseDate";
import TextareaAutosize from "react-autosize-textarea";

const Recipe = ({ admin }) => {
	const [tooltip, showTooltip] = useState(null);
	const { id } = useParams();

	const { recipes } = useData();

	const recipe = recipes.find((rec) => {
		return rec.id === id;
	});

	const RecipeElement = ({ recipe }) => {
		const date = new Date(recipe.date.seconds * 1000);
		const [notes] = useState("");

		return (
			<div className="relative py-4 overflow-hidden bg-white">
				<div className="relative px-4 sm:px-6 lg:px-8">
					<div className="mx-auto prose prose-lg text-gray-500 prose-indigo">
						<div className="mx-auto text-lg max-w-prose">
							<div className="flex justify-between pb-12 mx-auto text-base text-gray-700 print:hidden">
								<div className=" first-letter:capitalize">
									{parseDate(date)}
								</div>
								{!admin ? (
									<>
										<PrinterIcon
											data-for="print"
											data-tip
											className="w-8 h-8 hover:cursor-pointer hover:text-gray-500"
											onClick={() => {
												return window.print();
											}}
											onMouseEnter={() => {
												return showTooltip(true);
											}}
											onMouseLeave={() => {
												if (!admin) {
													showTooltip(false);
													setTimeout(() => {
														return showTooltip(
															true,
														);
													}, 50);
												}
											}}
											onBlur={() => {
												if (!admin) {
													showTooltip(false);
													setTimeout(() => {
														return showTooltip(
															true,
														);
													}, 50);
												}
											}}
										/>
									</>
								) : (
									<div className="group">
										<input
											type="file"
											id="image-file"
											accept="image"
											className="absolute z-0 opacity-0 print:hidden"
										/>
										<label htmlFor="image-file">
											<UploadIcon className="w-8 h-8 text-gray-700 rounded-full cursor-pointer hover:text-gray-800 group-focus-within:ring-2 group-focus-within:ring-offset-4 group-focus-within:ring-offset-white group-focus-within:ring-indigo-500" />
										</label>
									</div>
								)}

								{!admin && tooltip && (
									<ReactTooltip id="print" effect="solid">
										Imprimir
									</ReactTooltip>
								)}
							</div>
							{admin ? (
								<>
									<h1>
										<input
											value={recipe.yield}
											className="block w-full text-base font-semibold tracking-wide text-center text-indigo-600 uppercase rounded-sm hover:ring-2 ring-indigo-500 print:text-xs"
										/>

										<input
											value={recipe.title}
											className="block w-full mt-2 text-3xl font-extrabold leading-8 tracking-tight text-center text-gray-900 rounded-sm hover:ring-2 ring-indigo-500 print:text-xl sm:text-4xl"
										/>
									</h1>
									<TextareaAutosize
										value={recipe.description}
										className="w-full mt-4 text-xl leading-8 text-gray-500 border-none rounded-sm hover:ring-2 ring-indigo-500 print:text-base"
									/>
								</>
							) : (
								<>
									<h1>
										<span className="block text-base font-semibold tracking-wide text-center text-indigo-600 uppercase print:text-xs">
											{recipe.yield}
										</span>
										<span className="block mt-2 text-3xl font-extrabold leading-8 tracking-tight text-center text-gray-900 print:text-xl sm:text-4xl">
											{recipe.title}
										</span>
									</h1>
									<p className="mt-4 text-xl leading-8 text-gray-500 print:text-base">
										{recipe.description}
									</p>
								</>
							)}
						</div>

						<figure>
							<img
								className="w-full rounded-lg print:hidden"
								src={recipe.thumbnail}
								alt=""
								width={1310}
								height={873}
							/>
						</figure>

						<h3 className="print:text-lg">Ingredientes</h3>
						<ul className="print:text-base">
							{recipe.ingredients.map((ingredient, i) => {
								return (
									<li className="print:my-1" key={i}>
										{ingredient}
									</li>
								);
							})}
						</ul>
						<h3 className="print:text-lg">Procedimiento</h3>
						<ol className="print:text-base">
							{recipe.steps.map((step, i) => {
								return <li key={i}>{step}</li>;
							})}
						</ol>
						<h3 className="print:text-lg">Notas</h3>
						{notes ? "" : ""}
						<p className="print:text-base">{recipe.notes}</p>
					</div>
				</div>
			</div>
		);
	};

	return recipe ? <RecipeElement recipe={recipe} /> : <Loader />;
};

export default Recipe;
