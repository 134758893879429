import createDataContext from "@context/createDataContext";
import { database, collection, getDocs } from "@utils/firebase";

const reducer = (state, action) => {
	switch (action.type) {
		case "get_blogs":
			return action.payload;
		default:
			return state;
	}
};

const getBlogs = (dispatch) => {
	return async () => {
		const blogs = [];
		try {
			const querySnapshot = await getDocs(collection(database, "blogs"));
			querySnapshot.forEach((doc) => {
				const parsedDoc = { ...doc.data(), id: doc.id, type: 1 };
				blogs.push(parsedDoc);
			});
			const status = "success";
			dispatch({
				type: "get_blogs",
				payload: { data: blogs, status },
			});
		} catch (error) {
			console.log(error);
		}
	};
};

export const { Context, Provider } = createDataContext(
	reducer,
	{ getBlogs },
	{ data: [], status: "idle" },
);
