import { initializeApp } from "firebase/app";
import {
	getFirestore,
	collection,
	addDoc,
	doc,
	getDoc,
	query,
	where,
	getDocs,
	setDoc,
} from "firebase/firestore";
import { getAnalytics, logEvent } from "firebase/analytics";
import {
	getAuth,
	signInWithEmailAndPassword,
	onAuthStateChanged,
	signOut,
} from "firebase/auth";

const firebaseConfig = {
	apiKey: "AIzaSyDN3_tiuvLGZtKG9ytU0KGJUqMsqd_Y9Es",
	authDomain: "cooking-35f0a.firebaseapp.com",
	databaseURL: "https://cooking-35f0a.firebaseio.com",
	projectId: "cooking-35f0a",
	storageBucket: "cooking-35f0a.appspot.com",
	messagingSenderId: "807049873118",
	appId: "1:807049873118:web:b074f8b3c037697e84244f",
	measurementId: "G-0219R2EXH5",
};

const firebaseApp = initializeApp(firebaseConfig);

const database = getFirestore();
const analytics = getAnalytics();
const auth = getAuth(firebaseApp);

export {
	database,
	collection,
	addDoc,
	doc,
	getDoc,
	query,
	where,
	getDocs,
	setDoc,
	analytics,
	logEvent,
	auth,
	signInWithEmailAndPassword,
	onAuthStateChanged,
	signOut,
};
export default firebaseApp;
