import { MailIcon } from "@heroicons/react/solid";

const Input = ({ email, setEmail }) => {
	return (
		<div>
			<label
				htmlFor="email"
				className="block text-sm font-medium text-gray-700"
			>
				Email
			</label>
			<div className="relative mt-1 rounded-md shadow-sm">
				<div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
					<MailIcon
						className="w-5 h-5 text-gray-400"
						aria-hidden="true"
					/>
				</div>
				<input
					type="email"
					name="email"
					id="email"
					onChange={(e) => {
						setEmail(e.target.value);
					}}
					value={email}
					className="block w-full pl-10 border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
					placeholder="correo@mail.com"
				/>
			</div>
		</div>
	);
};

export default Input;
