import { collabs } from "@constants/index";

const LogoCloud = () => {
	return (
		/* This example requires Tailwind CSS v2.0+ */

		<div className="bg-white">
			<div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
				<p className="text-center text-base font-semibold uppercase text-gray-600 tracking-wider">
					Collaboracion con diversas marcas
				</p>
				<div className="mt-6 grid grid-cols-2 gap-0.5 md:grid-cols-3 lg:mt-8">
					{collabs.map((collab, i) => {
						return (
							<div
								key={i}
								className="col-span-1 flex justify-center py-8 px-8 bg-gray-50"
							>
								<img
									className={
										collab.height
											? collab.height
											: "max-h-20"
									}
									src={collab.logo}
									alt="collab.company"
								/>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default LogoCloud;
