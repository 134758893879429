import { useContext, useEffect } from "react";

import { Context as BlogContext } from "@context/BlogContext";
import { Context as RecipeContext } from "@context/RecipeContext";
import { Context as VideoContext } from "@context/VideoContext";

const useData = () => {
	const {
		state: { data: blogs, status: blogStatus },
		getBlogs,
	} = useContext(BlogContext);
	const {
		state: { data: recipes, status: recipeStatus },
		getRecipes,
	} = useContext(RecipeContext);
	const {
		state: { data: videos, status: videoStatus },
		getVideos,
	} = useContext(VideoContext);

	useEffect(() => {
		if (blogStatus === "idle") {
			getBlogs();
		}
	}, [blogStatus, getBlogs]);
	useEffect(() => {
		if (recipeStatus === "idle") {
			getRecipes();
		}
	}, [recipeStatus, getRecipes]);
	useEffect(() => {
		if (videoStatus === "idle") {
			getVideos();
		}
	}, [videoStatus, getVideos]);

	return {
		blogs,
		recipes,
		videos,
		data: [...blogs, ...recipes, ...videos],

		getBlogs,
		getVideos,
		getRecipes,

		blogStatus,
		recipeStatus,
		videoStatus,
	};
};

export default useData;
