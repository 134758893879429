import createDataContext from "@context/createDataContext";
import axios from "axios";
import { youtubeLink } from "@constants/index";

const REACT_APP_YOUTUBE_API_KEY = "AIzaSyCPH7RDftLriMitvwHGKxpsX6KnDPQUJ1c";
const REACT_APP_PLAYLIST_ID = "UU_-bZHk912HCFGRbyZBSacg";

const reducer = (state, action) => {
	switch (action.type) {
		case "get_videos":
			return action.payload;
		default:
			return state;
	}
};

const getVideos = (dispatch) => {
	return async () => {
		let token = null;
		const videos = [];
		const baseUrl =
			youtubeLink.base +
			REACT_APP_YOUTUBE_API_KEY +
			youtubeLink.playlist +
			REACT_APP_PLAYLIST_ID +
			youtubeLink.contentDetails;

		let parsedUrl = baseUrl;
		const queryVideos = () => {
			axios
				.get(parsedUrl)
				.then((response) => {
					token = response.data;
					response.data.items.forEach((element) => {
						const videoURL = `https://www.youtube.com/embed/${element.contentDetails.videoId}`;
						const videoTitle = element.snippet.title;
						const videoThumbnail =
							element.snippet.thumbnails.high.url;
						const parsedVideo = {
							URL: videoURL,
							title: videoTitle,
							thumbnail: videoThumbnail,
							id: element.contentDetails.videoId,
						};
						videos.push({ ...parsedVideo, type: 2 });
					});
					if (response.data.nextPageToken) {
						parsedUrl =
							baseUrl +
							youtubeLink.pageToken +
							token.nextPageToken;
						queryVideos();
					} else {
						const status = "success";
						dispatch({
							type: "get_videos",
							payload: { data: videos, status },
						});
					}
				})
				.catch((error) => {
					// Error
					if (error.response) {
						// The request was made and the server responded with a status code
						// that falls out of the range of 2xx
						// console.log(error.response.data);
						// console.log(error.response.status);
						// console.log(error.response.headers);
					} else if (error.request) {
						// The request was made but no response was received
						// `error.request` is an instance of XMLHttpRequest in the
						// browser and an instance of
						// http.ClientRequest in node.js
						console.log(error.request);
					} else {
						// Something happened in setting up the request that triggered an Error
						console.log("Error", error.message);
					}
					console.log(error.config);
				});
		};
		queryVideos(parsedUrl, token, baseUrl, youtubeLink.pageToken);
	};
};

export const { Context, Provider } = createDataContext(
	reducer,
	{ getVideos },
	{ data: [], status: "idle" },
);
