import { database, setDoc, doc } from "@utils/firebase";

const subscribe = async (email, recipe, blog, callback) => {
	try {
		await setDoc(doc(database, "newsletter", email), {
			blog,
			recipe,
		});
		callback;
	} catch (error) {
		console.log(error);
	}
};

export default subscribe;
