import { Fragment, useState } from "react";
import { FilterIcon, SearchIcon } from "@heroicons/react/solid";

import { Combobox, Dialog, Transition } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import useData from "@hooks/useData";

const Palette = () => {
	const { data } = useData();
	const [query, setQuery] = useState("");
	const [open, setOpen] = useState(false);
	const navigate = useNavigate();

	// eslint-disable-next-line func-style, space-before-function-paren
	function classNames(...classes) {
		return classes.filter(Boolean).join(" ");
	}

	const types = [
		{ label: "Receta", href: "recipes" },
		{ label: "Blog", href: "blogs" },
		{ label: "Video", href: "videos" },
	];

	(e) => {
		if (e.key === "Enter") {
			console.log("enter press");
		}
	};

	const filteredData =
		query === ""
			? []
			: data.filter((element) => {
					return element.title
						.toLowerCase()
						.includes(query.toLowerCase());
			  });

	return (
		<>
			<SearchIcon
				className="w-5 h-5 text-gray-400 bg-white rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
				aria-hidden="true"
				onClick={() => {
					setOpen(true);
				}}
			/>
			<Transition.Root
				show={open}
				as={Fragment}
				afterLeave={() => {
					setQuery("");
				}}
				appear
			>
				<Dialog
					as="div"
					className="relative z-10"
					onClose={() => {
						setOpen(false);
					}}
				>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0"
						enterTo="opacity-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100"
						leaveTo="opacity-0"
					>
						<div className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-25" />
					</Transition.Child>

					<div className="fixed inset-0 z-10 p-4 overflow-y-auto sm:p-6 md:p-20">
						<Transition.Child
							as={Fragment}
							enter="ease-out duration-300"
							enterFrom="opacity-0 scale-95"
							enterTo="opacity-100 scale-100"
							leave="ease-in duration-200"
							leaveFrom="opacity-100 scale-100"
							leaveTo="opacity-0 scale-95"
						>
							<Dialog.Panel className="max-w-xl mx-auto overflow-hidden transition-all transform bg-white divide-y divide-gray-100 shadow-2xl rounded-xl ring-1 ring-black ring-opacity-5">
								<Combobox
									onChange={(person) => {
										window.location = person.url;
									}}
								>
									<div className="relative">
										<FilterIcon
											className="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-400"
											aria-hidden="true"
										/>
										<Combobox.Input
											className="w-full h-12 pr-4 text-gray-900 bg-transparent border-0 pl-11 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
											placeholder="Search..."
											onChange={(event) => {
												setQuery(event.target.value);
											}}
										/>
									</div>

									{filteredData.length > 0 && (
										<Combobox.Options className="p-3 overflow-y-auto max-h-96 scroll-py-3">
											{filteredData.map((item) => {
												return (
													<Combobox.Option
														key={item.id}
														value={item}
														onClick={(e) => {
															e.preventDefault();
															navigate(
																`/${
																	types[
																		item
																			.type
																	].href
																}/${item.id}`,
															);
														}}
														className={({
															active,
														}) => {
															classNames(
																"hover:bg-slate-200 flex cursor-default rounded-xl my-2",
																active &&
																	"bg-gray-100",
															);
														}}
													>
														{({ active }) => {
															return (
																<div
																	className={`${
																		active
																			? "text-slate-800 bg-gray-100"
																			: ""
																	} flex items-center justify-center py-1 cursor-pointer`}
																>
																	<img
																		src={
																			item.thumbnail
																		}
																		alt="Thumbnail"
																		className="w-10 h-10 rounded-md"
																	/>

																	<div className="flex-auto ml-4">
																		<p
																			className={classNames(
																				"text-sm font-medium",
																				active
																					? "text-gray-900"
																					: "text-gray-700",
																			)}
																		>
																			{
																				item.title
																			}
																		</p>
																	</div>
																	<p
																		className={classNames(
																			"text-sm uppercase text-center flex-shrink-0 rounded-md p-1.5 mr-1",
																			active
																				? "bg-indigo-700 text-white"
																				: "bg-indigo-500 text-white",
																		)}
																	>
																		{
																			types[
																				item
																					.type
																			]
																				.label
																		}
																	</p>
																</div>
															);
														}}
													</Combobox.Option>
												);
											})}
										</Combobox.Options>
									)}

									{query !== "" &&
										filteredData.length === 0 && (
											<p className="p-4 text-sm text-gray-500">
												No se encontraron elementos
											</p>
										)}
								</Combobox>
							</Dialog.Panel>
						</Transition.Child>
					</div>
				</Dialog>
			</Transition.Root>
		</>
	);
};

export default Palette;
