import { newsletterElements } from "@constants/index";

const Checkbox = ({ blog, setBlog, recipe, setRecipe }) => {
	console.log(blog);
	return (
		<fieldset className="space-y-5">
			<legend className="sr-only">Notifications</legend>
			{newsletterElements.map((element, i) => {
				return (
					<div key={i} className="relative flex items-start">
						<div className="flex items-center h-5">
							<input
								id={element.tag}
								aria-describedby={element.tag}
								name={element.tag}
								type="checkbox"
								onChange={(e) => {
									i === 0
										? setRecipe(e.target.checked)
										: setBlog(e.target.checked);
								}}
								value={i === 0 ? recipe : blog}
								className="w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
							/>
						</div>
						<div className="ml-3 text-sm">
							<label
								htmlFor="comments"
								className="font-medium text-gray-700"
							>
								{element.tag}
							</label>
							<p
								id="comments-description"
								className="text-gray-500"
							>
								{element.description}
							</p>
						</div>
					</div>
				);
			})}
		</fieldset>
	);
};

export default Checkbox;
