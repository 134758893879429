import { Link } from "react-router-dom";
import Loader from "@atoms/Loader";
import { PencilAltIcon } from "@heroicons/react/outline";

const Grid = ({ data, status, type, admin }) => {
	const path = type ? "recipes/" : "";
	return status === "success" ? (
		<ul className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
			{data.map((element, i) => {
				return (
					<Link key={i} to={`${path}${element.id}`}>
						<li className="relative group">
							{admin && (
								<span className="absolute top-0 right-0 block h-10 w-10 rounded-tr-lg rounded-bl-3xl ring-2 ring-white z-50 bg-indigo-500 group-hover:bg-indigo-600">
									<PencilAltIcon className="absolute top-[.35rem] right-[.35rem] w-6 h-6 text-white mx-auto" />
								</span>
							)}
							<div className="block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden">
								<img
									src={element.thumbnail}
									alt=""
									className="object-cover pointer-events-none group-hover:opacity-75"
								/>

								<span className="sr-only">
									View details for {element.title}
								</span>
							</div>
							<p className="mt-2 block text-sm text-center lowercase first-letter:uppercase font-medium text-gray-900 pointer-events-none">
								{element.title}
							</p>
						</li>
					</Link>
				);
			})}
		</ul>
	) : (
		<Loader />
	);
};

export default Grid;
