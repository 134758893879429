import React, { useState, useEffect, useContext, createContext } from "react";
import {
	auth,
	signInWithEmailAndPassword,
	onAuthStateChanged,
	signOut,
} from "@utils/firebase";

const authContext = createContext();

export const AuthProvider = ({ children }) => {
	const auth = useProvideAuth();
	return <authContext.Provider value={auth}>{children}</authContext.Provider>;
};

export const useAuth = () => {
	return useContext(authContext);
};

const useProvideAuth = () => {
	const [user, setUser] = useState(null);
	const [error, setError] = useState(null);

	const signIn = (email, password) => {
		signInWithEmailAndPassword(auth, email, password)
			.then((res) => {
				setUser(res.user);
			})
			.catch((err) => {
				setError(err.code);
			});
	};

	const signout = () => {
		return signOut(auth).then(() => {
			setUser(false);
		});
	};

	useEffect(() => {
		const unsubscribe = onAuthStateChanged(auth, (user) => {
			if (user) {
				setUser(user);
			} else {
				setUser(false);
			}
		});

		return () => {
			return unsubscribe();
		};
	}, []);

	return {
		user,
		error,
		signIn,
		signout,
	};
};
